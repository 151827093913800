.App {
    margin: 0;
    display: block;
    place-items: center;
    min-height: 100vh;
    height: 100%;
    width: 100%;
    background: #1A2432 !important;
}

.Header {
    height: 10.93333vw;
    background: #243449;
    width: 100%;
    position: relative;
}

.App-logo {
    height: 9.333333vw;
    width: 23.33333vw;
    position: absolute;
    left: 4vw;
    top: 0.8vw;
}

.login-btn-group {
    position: absolute;
    height: 7.73333vw;
    width: 35vw;
    right: 2.66667vw;
    top: 1.86667vw;
    background: #0E141D;
    padding: 0 0.66667vw 0 0;
    border-radius: 1.333vw;
}

.login-btn {
    width: 30vw;
    height: 6.66667vw;
    position: relative;
    margin-top: 0.266667vw;
    font-size: 4.266667vw;
    color: #fff;
    text-align: center;
    float: right;
    background: transparent;
    border-radius: 1.0666vw;
    font-weight: 400;
    margin: 0.53333vw 1.33333vw 0 0;
    border: transparent;
    padding: 4px 15px;
}

.login-btn:hover {
    background: #FF8A00;
    color: #000;
}

.login-btn-icon {
    position: absolute;
    width: 3.2vw;
    fill: #fff;
    left: 0.66667vw;
}

.login-btn-text {
    margin-left: 2.66667vw;
}

.Main {
    width: 100vw;
    min-height: 100%;
}

.banner-show {
    background: #111111;
}

.carousel-img {
    border-radius: 2.66667vw;
    width: 92.4vw;
    margin: 2.26667vw 3.8vw 2.26667vw;
}

.tag-group {
    clear: both;
    padding: 0 4vw;
    height: 7.2vw;
    background: #1A2432;
}

.tag-group-bg {
    float: left;
    margin-top: 2.13333vw;
    padding: 0 0.666667vw;
    width: 92.4vw;
    height: 7.2vw;
    background: #0E141D;
    border-radius: 1.33333vw;
}

.tag-text {
    margin-left: 3.5vw;
}

.tag-btn {
    background: #0E141D;
    color: #fff;
    width: 27.066667vw;
    height: 6.133333vw;
    border-radius: 1.333333vw;
    font-size: 3.5vw;
    font-weight: 400;
    position: relative;
    margin-top: 0.466667vw;
    background: transparent;
    border: transparent;
    display: inline-block;
    text-align: center;
}

.tag-btn-active {
    background: #FF8A00;
    color: #000;
}

.tag-icon {
    position: absolute;
    width: 4.266667vw;
    fill: #fff;
    left: 2vw;
    top: 1.0vw;
}

.tag-btn-1 {
    width: 30vw;
}

.tag-btn-2 {
    width: 32vw;
}

.tag-btn-3 {
    width: 30vw;
}

.game-icon-group {
    padding: 0 4vw;
    margin-top: 5vw;
    /* margin: 1.3vw 1.3vw 30vw; */
    clear: both;
}

.game-icon {
    width: 21vw;
    border-radius: 3.5vw;
    margin: 1vw;
    /* margin: 1.3vw 1.5vw; */
}

.contact-bg {
    background: #000;
    opacity: 0.7;
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
}

.contact-content {
    width: 88vw;
    max-height: 122.4vw;
    background: rgba(32, 49, 74, 0.75);
    border-radius: 2.7vw;
    z-index: 156;
    border: 0.5px solid #466591;
    padding-bottom: 6.7vw;
    position: fixed;
    left: 50%;
    top: 30%;
    transform: translate(-50%, -50%);
}

.contact-logo {
    position: relative;
    height: 14vw;
    margin-bottom: 6vw;
    z-index: 1;
    width: 36vw;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
}

.contact-title {
    color: #fff;
    font-size: 6.4vw;
    text-align: center;
    margin-bottom: 4.8vw;
    margin-top: 16vw;
}

.contact-item-title {
    color: #fff;
    font-size: 4.8vw;
    text-align: center;
    margin-bottom: 2.4vw;
}